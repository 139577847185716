@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eaeaea !important;
  height: calc(100% + 50px) !important;
}

html {
  scroll-behavior: smooth !important;
  overflow-x: hidden;
  height: 1708px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 1230px) {
  html {
    height: 1758px !important;
  }
}

@media screen and (max-width: 937px) {
  html {
    height: 2250px !important;
  }
}
@media screen and (max-width: 600px) {
  html {
    height: 2580px !important;
  }
  html::-webkit-scrollbar {
    width: 0;
  }
}
