.animationContainer{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    position: fixed;
    max-width: 1600px;  
    max-height: 100vh; 
}
 
  .animationContainer .animationImg {
      max-width: 100%;
      height: 100vh;
}
