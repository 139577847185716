.animationContainerMobile {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  position: fixed;
  width: auto;
  max-height: 85vh;
  padding-top: 30px;
}

.animationContainerMobile .animationImgMobile {
  max-width: 100%;
  height: 85vh;
}
