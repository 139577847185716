.homeContainer {
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  height: 1700px;
}

.homeContent {
  position: absolute;
  z-index: 100;
  width: 100%;
}

.homeTitle {
  width: 495px;
  overflow: hidden;
  padding: 125px 0px 10px 180px;
}

.homeTitle .homeTitleText {
  font-size: 44px;
  font-weight: 600;
  line-height: 0.9;
}

@media screen and (max-width: 1239px) {
  .homeContainer {
    height: 1750px;
  }
}

@media screen and (max-width: 1002px) {
  .homeContainer {
    height: 1950px;
  }
}

@media only screen and (max-width: 937px) {
  .homeContainer {
    height: 2250px;
  }
}

@media only screen and (max-width: 600px) {
    .homeContainer {
        height: 2600px;
      }
  .homeTitle {
    width: 100%;
    padding: 100px 20px 10px 20px;
  }
  .homeTitle .homeTitleText {
    font-size: 28px;
  }
}