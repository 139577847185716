.BoxContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  top: 560px;
  padding: 0px 100px;
}

.boxes {
  width: 350px;
  height: 200px;
  padding: 18px 25px;

  background: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.7px);
  -webkit-backdrop-filter: blur(12.7px);
  border: 3px solid #ffffff;
}

.boxes h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0px;
}

.imgLogo {
  width: 100px;
}

.box3 .boxList {
  text-align: start;
  margin-top: 50px;
}

.box2 .boxList {
  text-align: end;
}

.boxList{
  margin-top: 30px;
  padding-left: 0px;
}

.boxList li {
  font-size: 14px;
  font-weight: 400;
}

ul {
  list-style-type: none;
}

.boxLineDot {
  display: flex;
  align-items: center;
  flex: 1 1 20px;
  z-index: 4;
}
.line {
  width: 100%;
  height: 2px;
  background-color: black;
}
.dot {
  width: 16px;
  height: 16px;
  background-color: black;
  margin-left: -8px;
  margin-right: -8px;
  border-radius: 50%;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

@media only screen and (max-width: 1310px) {
  .boxLineDot.boxLineDot2 {
    opacity: 0;
  }
}

@media only screen and (max-width: 1306px) {
  .boxLineDot.boxLineDot2 {
    max-width: 20px;
  }
  .box3.boxes {
    margin-top: 40px;
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 1285px) {
  .boxLineDot.boxLineDot2 {
    display: none;
  }
}

@media only screen and (max-width: 942px) {
  .boxLineDot.boxLineDot1, .box3.boxes ,.box2.boxes {
    opacity: 0;
  }
}

@media only screen and (max-width: 936px) {
  .boxLineDot.boxLineDot1 {
    max-width: 20px;
  }
  .box3.boxes {
    margin-left: 20px !important;
  }
  .box2.boxes {
    margin-top: 40px;
    margin-left: 0px !important;
  }}

@media only screen and (max-width: 922px) {
  .box1.boxes {
    width: 100%;
  }
  .boxLineDot.boxLineDot1 {
    display: none;
  }
  .box3.boxes {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 915px) {
  .boxes {
    width: 100%;
  }
  .box3.boxes,.box2.boxes {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .BoxContainer {
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 400px;
  }
  .boxes{
    width: 100%;
  }
  .boxLineDot.boxLineDot2,
  .boxLineDot.boxLineDot1 {
    display: flex;
    flex-direction: column;
    opacity: 1;
  }
  .line {
    width: 2px;
    height: 40px;
    background-color: black;
  }
  .dot {
    margin-top: -8px;
    margin-bottom: -8px;
  }

  .box3.boxes {
    margin-top: 0px;
    margin-left: 0px !important;
  }
  .box2.boxes {
    margin-top: 0px;
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 350px) {
  .boxes h3 {
    font-size: 16px;
  }
  .boxList li {
    font-size: 11px;
  }
  .imgLogo{
    width: 70px;
  }
}
