.imgLogo {
  width: 140px;
  height: auto;
}

.page-footer {
  position: relative;
  height: 40px;
  width: 100%;
  bottom: 0px;
}

.container-fluid {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.container-fluid .row {
  width: 100%;
}

.socialMediaIcon{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap:70px;
}
.socialMediaIcon img{
    height: 28px;
}

.isDisabled{
  display: none;
}

h6{
    margin-bottom: 0;
    margin-top: 12px;
}

.mobile{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
  gap:20px;
}


@media only screen and (max-width: 600px) {
  .page-footer {
    height: 60px;
  }
  .container-fluid{
    height: 60px;
    padding: 0;
    flex-wrap: nowrap;
  }
  .imgLogo {
   width: 70px;   
  }
  h6{
    font-size: 14px;
    margin-top: 0px;
  }
  .socialMediaIcon{
    width: 100%;
    justify-content: space-around;
    gap:30px;
    padding: 0px 30px;
  }
  .socialMediaIcon img{
    height: 26px;
}
}