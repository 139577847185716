.notificationContainer {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  max-height: 100%;
  padding: 0px 100px;
  margin-bottom: 50px;
  top: 1380px;
}

.notificationContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  max-width: 100%;
  height: fit-content;
  box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
  padding: 20px 70px;

  background: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.7px);
  -webkit-backdrop-filter: blur(12.7px);
  border: 3px solid #ffffff;
}

.inputContainer button {
  height: 36px;
  width: 100%;
  min-width: 110px;
  max-width: 130px;
  margin: 0px 20px;
  padding: 3px;
  font-size: 14px;
  border-radius: 6px;
  border: none;
  background-color: black;
  color: #ffffff;
  font-weight: 600;
}

.inputContainer {
  flex-grow: 1;
}

.inputContainer input {
  height: 36px;
  border-radius: 6px;
  outline: 1px solid black;
  border: none;
  width: 100%;
  max-width: 530px;
  font-style: italic;
  font-size: 13px;
  padding-left: 20px;
  color: black;
}

.inputContainer label {
  width: 100%;
  max-width: 530px;
}

.inputContainer ::placeholder {
  color: black;
}

.inputContainer form {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.inputContainer h4 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}

.inputContainer p {
  margin-top: -10px;
  font-size: 14px;
  color: black;
  font-weight: 500;
}

.clientsContainer {
  max-width: 350px;
  max-height: 160px;
}

.LogoContentTop,
.LogoContentBottom {
  display: flex;
  justify-content: center;
  height: 50%;
}
// .LogoContainer {
// }

.clientLogo {
  width: 50%;
  padding: 15px 30px;
}

// When screen is bigger

@media screen and (min-width: 800px) {
  .notificationContainer {
    left: -100%; /* You can adjust this value depending on your layout */
    top: 1560px; /* You can adjust this value depending on your layout */

    /* Animation properties */
    animation-name: slideIn;
    animation-duration: 1000ms;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  @keyframes slideIn {
    to {
      left: 0;
      top: 1380px;
    }
  }
}

// When screen is smaller

@media only screen and (max-width: 1125px) {
  .notificationContainer {
    top: 1400px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .notificationContainer {
    top: 1450px !important;
  }
}

@media screen and (max-width: 937px) {
  .notificationContainer {
    top: 1850px !important; /* You can adjust this value depending on your layout */
  }
}

@media only screen and (max-width: 900px) {
  .notificationContent {
    padding: 20px 30px;
  }
}

@media only screen and (max-width: 700px) {
  .inputContainer button {
    min-width: 80px;
    max-width: 100px;
    font-size: 12px;
  }
  .inputContainer p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .notificationContainer {
    padding: 0px 20px;
    top: 2160px !important;
  }

  .notificationContent {
    width: 100%;
    margin: 0 auto;
  }
  .inputContainer input {
    font-size: 10px;
    padding-left: 5px;
  }
  .inputContainer input {
    height: 26px;
  }
  .inputContainer button {
    font-size: 10px;
    height: 30px;
  }
}

@media screen and (max-width: 350px) {
  .inputContainer p {
    font-size: 10px;
  }
  form {
    display: flex;
    padding-top: 0px!important;
    flex-direction: column;
    gap: 10px;
  }
  .inputContainer button {
    min-width: 100%;
    margin: 0px;
  }
}
