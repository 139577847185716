.fixed-top,
.page-footer .container-fluid{
    max-width: 1600px;
    margin: 0 auto;
  }
.nav-link{
  font-size: 18px !important;
  font-weight: 500;
  color:black;
}
.nav-link:hover, .nav-link:focus , .nav-link:active , .nav-link:checked, .nav-link:visited{
  color: black !important;;
}
.navbar {
  background-color: #eaeaea;
}
.navbar-toggler{
  width: 100%;
  background: #eaeaea;
  color: black;
  margin: 0px 5px;
  text-align: right;
}

.navbar-toggler:focus{
  box-shadow:none;
}
.navbar-toggler-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.LogoMobile {
  width: 100px!important;
  float: left!important;
  padding-top: 5px;;
}

