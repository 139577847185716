.featuresContainer {
  display: flex;
  position: absolute;
  width: 100%;
  max-height: 100%;
  padding: 0px 100px;
  top: 1055px;
}

.featuresContent {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  gap: 40px;
  height: fit-content;
  box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
  padding: 25px 50px;

  background: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.7px);
  -webkit-backdrop-filter: blur(12.7px);
  border: 3px solid #ffffff;
}

.Box1 {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  margin-left: 20px;
}
.Box1 h2 {
  font-size: 26px;
  font-weight: 700;
  margin: 0;
}

.Box2 {
  display: flex;
  flex-direction: column;
  flex: 3;
  gap: 15px;
}

.Box3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 0px;
}

.TextBox {
  display: flex;
  gap: 20px;
  font-size: 16px;
  font-weight: 500;
}

.TextBox span:first-of-type{
  display: none;
  margin-top: 10px;;
}

.TextBox.TextBox1 {
  position: relative;
  top: 3px;
}

.TextBox.TextBox2 {
  position: relative;
  top: 7px;
}

.TextBox.TextBox4 {
  position: relative;
  top: -3px;
}

@media screen and (min-width: 950px) {
  .featuresContainer {
    left: 100%; /* You can adjust this value depending on your layout */
    top: 1400px; /* You can adjust this value depending on your layout */
    /* Animation properties */
    animation-name: slideIn;
    animation-duration: 1000ms;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  @keyframes slideIn {
    to {
      left: 0;
      top: 1055px;
    }
  }
}

@media screen and (max-width: 937px) {
  .featuresContainer { 
    top: 1300px; /* You can adjust this value depending on your layout */
   }
  }

@media screen and (max-width: 810px) {
  .featuresContent {
    flex-direction: column;
  }
  .Box3{
    display:none;
  }
  .featuresContent {
    padding: 25px 25px;
}
.TextBox span:first-of-type{
  display: block;
  margin-top: 10px;;
}
}

@media screen and (max-width: 600px) {
  .featuresContainer {
    top: 1400px!important; /* You can adjust this value depending on your layout */
    padding: 0px 20px;
  }
  
  .featuresContent {
    padding: 25px 25px;  
  }
}

@media screen and (max-width: 350px) {
  .TextBox {
    font-size: 12px;
  }
}