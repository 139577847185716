//Navbar position
.navbarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  margin: 10px 30px 0px 30px;
  z-index: 1050;
}

.navLinkRight {  
  display: flex;
  align-items: center;
}

.navLinkRight a{
  position: relative;
  top:-10px;
  letter-spacing: 2px;
}

.navLinkRight a:hover{
font-weight: 700;
letter-spacing: 1.7px;
}

.navLinksLeft {
  margin-left: 0px;
}

@media only screen and (max-width: 570px) {
  .navLinkRight {
    flex-direction: column;
    padding-top: 20px;
  }
  .navbarContainer {
    margin: 0;
    height: auto;
    background-color: #ebebeb;
  }
}
